import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import {InterviewHandler} from "./interview-handler";
import {SessionHandler} from "./session-handler";

onDOMContentLoaded(() => {

    // @ts-ignore
    const trans = (key: string) => translations[key];

    const interviewElement = document.querySelector('.dws__account-docassemble__interview');
    if (interviewElement instanceof HTMLElement) {
        new InterviewHandler(interviewElement, trans);
    }

    const sessionElement = document.querySelector('.dws__account-docassemble__session');
    if (!(sessionElement instanceof HTMLElement)) return;
    new SessionHandler(sessionElement, trans);
});
