// @ts-ignore
import {polyfill} from 'smoothscroll-polyfill';

// initialize the smoothscroll polyfill
polyfill();

// region INTERFACES
export interface TopButtonElements {
    containerElement: Element | null,
    topButtonElements: NodeListOf<Element> | null
}
// endregion INTERFACES

/**
 * To-top button
 * scrolls to top of page onclick
 */
export default class TopButton {
    private static readonly States = {
        Visible: 'as__top-button--visible',
        Frozen: 'as__top-button--frozen'
    };

    public static readonly Selectors = {
        TopButton: '.as__top-button',
        Container: '.as__top-button__container'
    };

    private static readonly Attributes = {
        VisibilityOffset: 'data-visible-after',
        Target: 'href'
    };

    private elements : TopButtonElements;

    constructor(element : Element | null) {
        if (!(element instanceof Element)) {
            throw new Error('could not find top button container element');
        }

        this.elements = {
            containerElement: element,
            topButtonElements: element.querySelectorAll(TopButton.Selectors.TopButton)
        };

        this.elements.topButtonElements?.forEach((topButtonElement: Element) => {
            if (!(topButtonElement instanceof Element)) {
                throw new Error('could not find top button element');
            }

            topButtonElement.addEventListener('click', (event) => this.onClick(event), false);
            topButtonElement.addEventListener('keydown', (event) => this.onKeydown(<any>event), false);
        })

        window.addEventListener('scroll', this.onScroll.bind(this), false);
    }

    /**
     * Get computed value of 'bottom' style prop of element
     *
     */
    private getBottomStyleVal(element : Element) : number {
        const elementStyles = window.getComputedStyle(element),
              elementBottomString = elementStyles.getPropertyValue('bottom').match(/\d+/);

        return elementBottomString ? parseInt(elementBottomString[0], 10) : 0;
    }

    /**
     * Wrapper function for window scroll related functions
     *
     */
    private onScroll() : void {
        const currentScrollTop = window.pageYOffset;
        this.elements.topButtonElements?.forEach((topButton: Element) => {
            if (!(topButton instanceof Element)) {
                return;
            }

            this.toggleTopButton(topButton, currentScrollTop);
            this.freezeTopButton(topButton);
        });
    }

    /**
     * Toggle top button element if current scroll top is higher than visibility offset
     *
     */
    private toggleTopButton(topButton : Element, currentScrollTop : number) : void {
        if (currentScrollTop > parseInt(topButton.getAttribute(TopButton.Attributes.VisibilityOffset) || '0', 10)) {
            topButton.classList.add(TopButton.States.Visible);
        } else {
            topButton.classList.remove(TopButton.States.Visible);
        }
    }

    /**
     * Freeze top button element in place if container element is in view
     *
     */
    private freezeTopButton(topButton : Element) : void {
        const containerElement = this.elements.containerElement;

        if (!(containerElement instanceof Element)) {
            return;
        }


        if (this.isWithinViewPort(containerElement)) {
            topButton.classList.add(TopButton.States.Frozen);
        } else {
            topButton.classList.remove(TopButton.States.Frozen);
        }
    }

    /**
     * Check if element is in view based on element top
     *
     */
    private isWithinViewPort (element : Element, offset : number = 0) : boolean {
        const top = element.getBoundingClientRect().top,
              bottom = element.getBoundingClientRect().bottom;

        return (top > 0 || bottom > 0) && top + offset < window.innerHeight;
    }

    /**
     * Wrapper function for top button click related functions
     *
     */
    private onClick(event : Event) : void {
        event.preventDefault();

        if (!(event.target instanceof Element)) {
            return;
        }

        let link = event.target.closest('a');
        if (!link) return;

        let goToElement = document.querySelector(link.getAttribute(TopButton.Attributes.Target) || '');
        if (!goToElement) return;

        goToElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    /**
     * Execute onClick-handler if "enter" key is pressed
     *
     */
    private onKeydown(event : KeyboardEvent) : void {
        if (!(event instanceof KeyboardEvent) || event.keyCode !== 13) {
            return;
        }

        this.onClick(event);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    let topButtonContainer = document.querySelector(TopButton.Selectors.Container);

    try {
        new TopButton(topButtonContainer);
    } catch (e) {
        console.error(e);
    }
}, false);
