import Accordion from "../../components/base-accordion/accordion";
import Fuse from "fuse.js";
import Loading from "../../components/loading/loading";
import {CUSTOM_SEARCH_EVENT, CUSTOM_INTERVIEWS_UPDATED} from "./session-handler";
import {DeepPartial} from "../../base/util/assignOptions";
import BaseSlider, {BaseSliderOptions} from "../../components/base-slider/base-slider";
import BaseSliderItem from "../../components/base-slider/base-slider-item";
import TopButton from "../../modules/sr3a-top-button/top-button";

interface DocassembleInterview {
    id: number;
    title: string;
    subtitle?: string;
    tags: DocassembleInterviewTag[];
    metaTitle?: string;
    metaSubtitle?: string;
    shortTitle?: string;
    description?: string;
    interviewUrl?: string;
    isFavorite: boolean,
    licensed: boolean,
    publishingDate?: number,
    licenseEnddate?: number,
    labels?: string[],
    price?: string,
    buyLink?: string
}

interface DocassembleInterviewTag {
    id: number;
    label: string;
    priority: number;
}

const CUSTOM_INTERVIEW_RENDER_EVENT: string = 'interviewRenderEvent';

class InterviewHandler {
    private readonly element: HTMLElement;
    private readonly docassembleInterviewsEndpoint: string | null;
    private interviewStorage: DocassembleInterview[] = [];
    private lastPurchasedInterviewIdsStorage: number[] = [];
    private expiredInterviewIdsStorage: number[] = [];
    private favoriteInterviewIdsStorage: number[] = [];
    private isInterviewFavoriteSliderRendered: boolean = false;
    private searchTerm: string = '';
    private searchResultsContainer: HTMLElement | null;
    private interviewResultContainer: HTMLElement | null;
    private openAccordionItemId: string = '';
    private _trans: Function;

    constructor(element: HTMLElement, trans: Function) {
        this.element = element;
        this._trans = trans;
        this.docassembleInterviewsEndpoint = element.getAttribute('data-docassemble-interviews') ?? null;
        this.interviewResultContainer = document.getElementById('dws__account-docassemble__interview-listing-results') ?? null;
        this.searchResultsContainer = document.getElementById('dws__account-docassemble__interview-listing-search-results') ?? null;

        this.initComponent();
    }

    private async initComponent(): Promise<void> {
        const loading = this.initLoading();

        try {
            await this.fetchInterviewData();
            this.renderInterviewListing();
            this.renderInterviewSliderModule();
        } finally {
            loading?.destroy();
        }

        this.setupFormListeners();
        document.addEventListener(CUSTOM_INTERVIEW_RENDER_EVENT, this.handleRenderEvent.bind(this))
    }

    private async fetchInterviewData(): Promise<void> {
        if (!this.docassembleInterviewsEndpoint) return;

        try {
            const response = await fetch(this.docassembleInterviewsEndpoint);
            const data = await response.json();
            this.interviewStorage = data?.interviews ?? [];
            this.lastPurchasedInterviewIdsStorage = data?.lastPurchasedInterviews ?? [];
            this.expiredInterviewIdsStorage = data?.expiredInterviews ?? [];

            const responseFav = await fetch('/Vordrucke/Api/Interviews/Favorites');
            const dataFav = await responseFav.json();
            this.favoriteInterviewIdsStorage = dataFav.favorites ?? [];
        } catch (error) {
            console.error('Failed to fetch docassemble interviews:', error);
        }
    }

    private setupFormListeners(): void {
        const interviewSearchForm = document.querySelector('.dws__account-docassemble__interview-search-form');

        if (!(interviewSearchForm instanceof HTMLFormElement)) return;

        interviewSearchForm.addEventListener('submit', this.handleFormSubmit.bind(this));

        const interviewSearchInput = document.querySelector('.as__input.dws__account-docassemble__interview-search-input');

        if (!(interviewSearchInput instanceof HTMLInputElement)) return;

        interviewSearchInput.addEventListener('input', this.handleFormInput.bind(this));
    }

    private handleFormSubmit(event: Event): void {
        event.preventDefault();

        if (!(event.target instanceof HTMLFormElement)) return;
        // @ts-ignore
        let term = event.target.elements["term"].value;
        this.searchTerm = term;

        this.searchInterviewsByTerm(term).then(this.renderSearchResults.bind(this));
    }

    private handleFormInput(event: Event): void {
        if (!(event.target instanceof HTMLInputElement)) return;
        // @ts-ignore
        let term = event.target.value;

        if (!term && this.searchResultsContainer instanceof HTMLElement) {
            this.searchResultsContainer.innerHTML = '';
        }
    }

    private async handleRenderEvent(): Promise<void> {
        await this.fetchInterviewData();

        let favButtons = document.querySelectorAll('.as__btn--notice');
        favButtons.forEach(favButton => {
            favButton.removeEventListener('click', this.handleInterviewFavorite.bind(this));
        });

        this.searchInterviewsByTerm(this.searchTerm).then(this.renderSearchResults.bind(this));

        let openItemsList: (string | null)[] = [];
        let items = document.querySelectorAll('#dws__account-docassemble__interview-listing .as__accordion__label');
        items?.forEach(i => {
            openItemsList.push(i.getAttribute('aria-expanded'));
        });

        this.renderInterviewListing();

        let newItems = document.querySelectorAll('#dws__account-docassemble__interview-listing .as__accordion__label');

        newItems?.forEach(i => {
           i.setAttribute('aria-expanded', <string>openItemsList.shift());
        });

        try {
            this.renderInterviewSliderModule();
        } catch (err) {
            console.error(err);
        }
    }

    private async searchInterviewsByTerm(term: string): Promise<DocassembleInterview[]> {
        const options = {
            includeScore: true,
            keys: ['title', 'subtitle', 'shortTitle', 'tags.label']
        };
        const fuse = new Fuse(this.interviewStorage, options);
        return fuse.search(term).map(result => result.item);
    }

    private renderInterviewListing(): void {
        let container = this.interviewResultContainer;
        if (!container) return;

        container.innerHTML = '';

        const interviewGroup = new Map<string, DocassembleInterview[]>();

        this.interviewStorage
            .sort((a, b) => {
                let tagsA = a.tags[0]?.label ?? '';
                let tagsB = b.tags[0]?.label ?? '';

                return tagsA?.localeCompare(tagsB);
            })
            .forEach(interview => {
                interview.tags.forEach(tag => {
                    const current = interviewGroup.get(tag.label) ?? [];
                    interviewGroup.set(tag.label, [...current, interview]);
            });
        });

        let accordionHTML = Array.from(interviewGroup.entries())
            .map(([title, interviews]) => this.renderAccordion(title, interviews)).join('');

        let hasExpiredInterviews = this.expiredInterviewIdsStorage.length > 0;
        if (hasExpiredInterviews) {
            let expiredInterviews = this.interviewStorage.filter(item => {
                return this.expiredInterviewIdsStorage.includes(item.id);
            })

            accordionHTML = accordionHTML + this.renderAccordion(
                this._trans('account.docassemble.expired.forms'),
                expiredInterviews,
                `<span class="as__text-small" style="color: #faf9f8;">${this._trans('account.docassemble.already.purchased.forms')}</span>`
            )
        }

        container.innerHTML = `
            <div class="as__accordion__group as__mb-10">
                <div class="dws__m21__accordion as__accordion color-brand-primary-yellow ${hasExpiredInterviews ? 'has-expired-accordion' : ''}">
                    ${accordionHTML}
                </div>
            </div>
        `;

        this.element.querySelectorAll(Accordion.DefaultOptions.selectors.accordion).forEach(accordionElement => {
            new Accordion(accordionElement as HTMLElement);


            let accordionToggles = accordionElement.querySelectorAll('input[type=checkbox]');

            accordionToggles.forEach(checkbox => {
                checkbox.addEventListener('change', event => {
                    let checkbox = event.target as HTMLInputElement;

                    if (checkbox.checked) {
                        this.openAccordionItemId = checkbox.id
                    }
                });
            })
        });

        this.registerButtonClickEvents(container);

        document.dispatchEvent(new CustomEvent(CUSTOM_INTERVIEWS_UPDATED, {}));
    }

    private registerButtonClickEvents(container: HTMLElement): void {
        let openLinkButtons = container.querySelectorAll('.dws__account-docassemble__interview-open-version-link');
        let favButtons = container.querySelectorAll('.as__btn--notice');

        openLinkButtons.forEach(btn => {
            btn.addEventListener('click', this.handleInterviewOpenVersion.bind(this));
        });

        favButtons.forEach(btn => {
            btn.addEventListener('click', this.handleInterviewFavorite.bind(this));
        });
    }

    private handleInterviewOpenVersion(event: Event): void {
        const target = event.target as HTMLElement;

        if (target.classList.contains('dws__account-docassemble__interview-open-version-link')) {
            document.dispatchEvent(new CustomEvent(CUSTOM_SEARCH_EVENT));
        }
    }

    private handleInterviewFavorite(event: Event): void {
        const target = event.target as HTMLElement;
        let btn = target.closest('.as__btn--notice')

        if (!btn) return;

        let favItemId = '';
        if (target.classList.contains('as__btn--notice')) {
            favItemId = target.getAttribute('data-docassemble-interview-id') ?? '';
        } else {
            favItemId = target.parentElement?.getAttribute('data-docassemble-interview-id') ?? '';
        }

        if (favItemId == '') {
            console.error('No valid interview id given. Expected number. Given: "' + favItemId + '"');
            return;
        }

        const item = this.interviewStorage.filter(item => item.id == Number(favItemId)).pop();

        if (!item) {
            return;
        }

        let opts: RequestInit = {
            method: this.favoriteInterviewIdsStorage.includes(item.id) ? 'DELETE' : 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        fetch(`/Vordrucke/Api/Interviews/${item.id}/Favorite`, opts)
            .then(response => {
                console.log(response);
                if (response.status == 204) {
                    this.favoriteInterviewIdsStorage = this.favoriteInterviewIdsStorage.filter(favId => favId !== item.id)
                }

                if (response.status == 201) {
                    if (!this.favoriteInterviewIdsStorage.includes(item.id)) {
                        this.favoriteInterviewIdsStorage.push(item.id);
                    }
                }

                if (response.status == 403) {
                    window.location.href = "/account/login";
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });

        document.dispatchEvent(new CustomEvent(CUSTOM_INTERVIEW_RENDER_EVENT, {}));
    }

    private renderAccordion(title: string, interviews: DocassembleInterview[], labelHtml = ''): string {
        const id = Math.random().toString(16).slice(2);
        const itemsHTML = interviews
            .sort((itemA, itemB) => {
                if (itemA.licensed !== itemB.licensed) {
                    return itemA.licensed ? -1 : 1;
                }

                return (itemA.shortTitle ?? '').localeCompare(itemB.shortTitle ?? '', undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            })
            .map(item => this.renderInterviewItem(item, 'as__mb-6 as__col-9 as__col-md-4 as__col-xl-3'))
            .join('');

        if (labelHtml === '') {
            let count = interviews.length;
            let countLicenced = interviews.filter(item => item.licensed).length;
            labelHtml = `
            <span class="as__text-small as__text-color--${ countLicenced == 0 ? 'brand-secondary-regular' : 'brand-primary-yellow' }">
                ${countLicenced} von ${count} ${this._trans('account.docassemble.forms.licensed')}
            </span>
            `;
        }

        return `
            <input id="as__accordion__item-interview_accordion_${id}" class="as__accordion__toggle" type="checkbox" name="as__accordion-interview_accordion_${id}">
            <div class="as__accordion__item" data-accordion-id="as__accordion__item-interview_accordion_${id}">
                <label class="as__accordion__label" for="as__accordion__item-interview_accordion_${id}" aria-expanded="false">
                    ${title}
                    <br>
                    ${labelHtml}   
                    <svg role="img" class="as__icon">
                        <use xlink:href="#as__icon__arrow"></use>
                    </svg>
                </label>
                <div class="as__accordion__content">
                    <div class="as__accordion__content-wrapper">
                        <div class="as__row">${itemsHTML}</div>
                    </div>
                </div>
            </div>
        `;
    }

    private renderInterviewItem(item: DocassembleInterview, wrapperClass: string = ''): string {
        let navigationHtml = '';

        if (item.licensed) {
            navigationHtml = `
                <div class="as__row">
                    <div class="as__col-6">
                        <a href="${item.interviewUrl}" class="as__link as__link--icon as__text__medium">
                            <svg role="img" class="as__icon as__link--icon__icon">
                                <use xlink:href="#as__icon__link-icon--small"></use>
                            </svg>
                            Ausfüllen
                        </a>
                    </div>
                    <div class="as__col-6">
                        <a class="as__link as__link--icon as__text__medium dws__account-docassemble__interview-open-version-link" data-docassemble-interview-open-version-link>
                            <svg role="img" class="as__icon dws__docassemble-link-icon--clock">
                                <use xlink:href="#as__icon__clock"></use>
                            </svg>
                           ${this._trans('account.docassemble.history')} 
                        </a>
                    </div>
                </div>
            `;
        } else {
            navigationHtml = `
                <div class="as__show--flex as__bottom">
                    <span class="dws__product-carousel-tile__price"> 
                        ${item.price}<sup>*</sup> 
                    </span>
                    <div class="dws__product-carousel-tile__links as__show--flex as__end as__middle as__ml-auto">
                        <a href="${item.buyLink}" class="as__btn as__mb-0">
                            ${ Array.isArray(this.expiredInterviewIdsStorage) && this.expiredInterviewIdsStorage.includes(item.id) ? this._trans('account.docassemble.btn.reorder') : this._trans('account.docassemble.btn.detailpage') }
                        </a>
                    </div>
                </div>
            `;
        }
        let labelsHtml = '';
        if (item.labels) {
            labelsHtml = item.labels
                .map(label => {
                    if (!label) {
                        return '';
                    }

                    return `<span class="dws__tag dws__tag--target" style="position: absolute; right: 0; top: 0;">${label}</span>`
                })
                .join('');
        }

        let licensedEnddateHtml = '';
        if (item.licenseEnddate) {
            const licenseEndate = new Date(item.licenseEnddate * 1000);
            const day = licenseEndate.getDate().toString().padStart(2, '0');
            const month = (licenseEndate.getMonth() + 1).toString().padStart(2, '0');
            const year = licenseEndate.getFullYear().toString().slice(-2);
            licensedEnddateHtml = `<span class="as__text-small as__text-color--brand-primary-yellow" style="font-weight: 500;">Lizenziert bis ${day}.${month}.${year}</span>`;
        }

        let publishingDateHtml = '';
        if (item.publishingDate) {
            const publishingDate = new Date(item.publishingDate * 1000);
            const month = (publishingDate.getMonth() + 1).toString().padStart(2, '0');
            const year = publishingDate.getFullYear().toString();
            publishingDateHtml = `Stand ${month}/${year}`;
        }

        let interviewItemsHTML = `
        <div class="dws__product-carousel-tile as__carousel__content as__pt-4" data-docassemble-interview-id="${item.id}">
            <div class="dws__product-carousel-tile__slide-top">
                <div class="dws__product-carousel-tile__tags as__w-100 as__show--flex" style="position: relative;">
                    ${labelsHtml}
                </div>
                <div class="as__row as__mb-2 as__pl-3 as__pt-3">
                    <a href="#addOrRemove" class="as__btn as__btn--notice ${ this.favoriteInterviewIdsStorage.includes(item.id) ? 'as__btn--notice-active' : '' } as__btn--icon as__mr-3" data-docassemble-interview-id="${item.id}">
                        <svg role="img" class="as__icon as__btn--icon__icon as__btn--notice__icon-inactive">
                            <use xlink:href="#as__icon__favorite-star"></use>
                        </svg>
                        <svg role="img" class="as__icon as__btn--icon__icon as__btn--notice__icon-active">
                            <use xlink:href="#as__icon__favorite-star--filled"></use>
                        </svg>
                    </a>
                    <div>
                        <span class="as__text-small">${item.shortTitle ?? ''}<br>${publishingDateHtml}</span>
                    </div>
                </div>
                <div class="as__row as__pl-3 as__pl-lg-0">
                    <div class="as__col-12 as__show--flex as__middle as__mb-1"></div>
                    <div class="as__col-12">
                        <h5 class="as__h5 as__text-color--brand-primary-red">
                            ${item.title}
                            <span class="as__text as__text-color__grey-dark">${item.subtitle ?? ''}</span>
                        </h5>
                        <p class="as__text-small as__text-color--brand-secondary-regular">${item.description ?? ''}</p>
                    </div>
                </div>
            </div>
            <div class="as__mt-6 as__pl-3 as__pl-lg-0">
                <div class="as__row">
                    <div class="as__col-12 as__mb-2">
                       ${licensedEnddateHtml} 
                    </div>
                    <div class="as__col-12 as__pl-3">${navigationHtml}</div>
                </div>
            </div>
        </div>
        `;

        if (wrapperClass) {
            interviewItemsHTML = `<div class="${wrapperClass}">` + interviewItemsHTML + '</div>'
        }

        return interviewItemsHTML;
    }

    private renderSearchResults(results: DocassembleInterview[]): void {
        const container = this.searchResultsContainer;
        if (!container) return;

        if (!this.searchTerm && this.searchResultsContainer instanceof HTMLElement) {
            this.searchResultsContainer.innerHTML = '';
        }

        container.innerHTML = '';
        let interviewsHTML = `
            <div class="as__col">
                <p class="as__text-small">${this._trans('account.docassemble.empty.forms.search')}</p>
            </div>
        `;

        if (results.length > 0) {
            interviewsHTML = results
                .map(item => this.renderInterviewItem(item, 'as__mb-6 as__col-9 as__col-md-4 as__col-xl-3'))
                .join('');

        }

        container.innerHTML = `<div class="as__row">${interviewsHTML}</div>`;
        document.dispatchEvent(new CustomEvent(CUSTOM_INTERVIEWS_UPDATED, {}));
        this.registerButtonClickEvents(container);
    }

    private initLoading(): { destroy: () => void } | null {
        const trigger = document.querySelector('.js-loading__account-interviews') as HTMLElement;
        if (!trigger) return null;

        trigger.style.height = '100px';
        trigger.style.position = 'relative';
        const loading = new Loading(trigger, 'triple');

        return {
            destroy: () => {
                loading.destroy();
                trigger.style.height = '0';
            }
        };
    }

    private renderInterviewSliderModule() {
        const sliderContainer = document.getElementById('dws__account-docassemble__slider-container');

        if (!sliderContainer) {
            console.error('element for slider container is missing');
            return;
        }

        sliderContainer.innerHTML = '';

        const lastPurchasesHTML = this.renderLastPurchasedInterviews();
        const favoriteHTML = this.renderFavoriteInterviews();

        sliderContainer.innerHTML = `
        <div class="as__module dws__account-docassemble">
            ${lastPurchasesHTML}
            ${favoriteHTML}
        </div>
        `;

        sliderContainer.querySelectorAll('.dws__account-docassemble-interview__slider').forEach((carouselElement) => {
            if (!(carouselElement instanceof HTMLElement)) {
                return;
            }

            const settings: DeepPartial<BaseSliderOptions> = {
                selectors: {
                    container: '.dws__account-docassemble-interview__slider-container',
                    items: '.dws__account-docassemble-interview__slide',
                },
                grid: {
                    adaptItemHeight: true,
                    itemHeightCalculator: (item: BaseSliderItem, options: BaseSliderOptions) => {
                        const height = BaseSlider.DefaultOptions.grid.itemHeightCalculator(item, options);
                        if (options.grid.adaptItemHeight) {
                            return height + 10;
                        }
                        return height;
                    }
                },
                arrows: {
                    enabled: false,
                },
                swipe: {
                    stepType: 'item',
                },
                infinite: {
                    enabled: false,
                },
            };

            delete Hammer.defaults.cssProps.userSelect;
            new BaseSlider(carouselElement, settings);
        });

        // post-load top button when slider exists else hide it
        let anchorElement = document.querySelector('.as__top-button[href="#favorites"]');
        if (this.isInterviewFavoriteSliderRendered && anchorElement) {
            new TopButton(anchorElement?.parentElement);
        } else {
            anchorElement?.classList.add('as__hide');

            let m35NavigationTileFavorite = document.querySelector('.dws__m35-forwarding-tile-account__item-link[href="#favorites"]');
            m35NavigationTileFavorite?.parentElement?.classList.add('as__hide');
        }

        this.registerButtonClickEvents(sliderContainer);
    }

    private renderLastPurchasedInterviews(): string {
        let items = this.interviewStorage.filter(item => {
            return this.lastPurchasedInterviewIdsStorage.includes(item.id)
        });

        if (items.length <= 0) {
            return '';
        }

        const sliderHTML = this.renderSliderComponent(items);

        return `
        <div class="as__container-fullwidth" style="position: relative; bottom: -4px">
            <div class="dws__m63a-favourite-list-factsheet">
                <div class="dws__m63a-favourite-list-factsheet__header as__container as__mb-4">
                    <div class="as__show--flex as__middle">
                        <h2 class="as__h2 as__text__medium as__mb-0">
                            <div class="as__show--flex">
                                <svg role="img" class="as__icon as__text-color--brand-primary-red as__mr-4">
                                    <use xlink:href="#as__icon__cart"></use>
                                </svg>
                                <h2 class="as__h2 as__text__medium as__mb-0">${this._trans('account.docassemble.last.purchased.forms')}</h2>
                            </div>
                        </h2>
                    </div>
                </div>
                
                ${sliderHTML}
            </div> 
        </div>
        `;
    }

    private renderFavoriteInterviews(): string {
        let items = this.interviewStorage.filter(item => {
            return this.favoriteInterviewIdsStorage.includes(item.id);
        });

        if (items.length <= 0) {
            return '';
        }

        let favItemsHTML = '';
        let backgroundModifierClass = '';

        if (window.innerWidth >= 1024) {
            let gridHTML = items
                .map(item => this.renderInterviewItem(item, 'as__mb-6 as__col-9 as__col-md-4 as__col-xl-3'))
                .join('');

            favItemsHTML = `
                <div class="as__container" style="position: relative; z-index: 10;">
                    <div class="as__row">
                        ${gridHTML}
                    </div>
                </div>
            `;

            backgroundModifierClass = 'as__background__position-bottom';
        } else {
            favItemsHTML = this.renderSliderComponent(items);
        }

        this.isInterviewFavoriteSliderRendered = true;

        return `
        <div class="as__container-fullwidth">
            <div class="dws__m63a-favourite-list-factsheet as__rotate ${backgroundModifierClass}">
                <div class="dws__m63a-favourite-list-factsheet__header as__container as__mb-4">
                    <div class="as__show--flex as__middle">
                        <h2 class="as__h2 as__text__medium as__mb-0">
                            <div class="as__show--flex" style="color: #ffffff;">
                                <svg role="img" class="as__icon as__mr-2">
                                    <use xlink:href="#as__icon__favorite-star"></use>
                                </svg>
                                <h2 id="favorites" class="as__h2 as__text__medium as__mb-0" style="color: #ffffff">${this._trans('account.docassemble.favorites')}</h2>
                            </div>
                        </h2>
                    </div>
                </div>
                
                ${favItemsHTML}
            </div>
        </div>
        `;
    }

    private renderSliderComponent(items: DocassembleInterview[]): string {
        let sliderHTML = '';
        const itemsHTML = items
            .map(item => this.renderInterviewItem(item, 'dws__account-docassemble-interview__slide as__col-9 as__col-md-4 as__col-xl-3 as__mx-auto as__mx-md-0'))
            .join('');

        sliderHTML = `
        <div class="dws__account-docassemble-interview__slider as__pb-lg-6">
            <div class="dws__account-docassemble-interview__slider-container as__row">
                ${itemsHTML}    
            </div>
        </div>
        `;

        return sliderHTML;
    }
}

export { DocassembleInterview, DocassembleInterviewTag, InterviewHandler };
