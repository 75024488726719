import Fuse from "fuse.js";
import {DocassembleInterview} from "./interview-handler";

interface DocassembleSession {
    sessionId: string;
    modificationDate: string;
    startDate?: string;
    interview: DocassembleInterview;
    interviewUrl: string;
    deleteUrl: string;
}

const CUSTOM_SEARCH_EVENT: string = 'searchSessionsEvent';
const CUSTOM_INTERVIEWS_UPDATED: string = 'interviewUpdateEvent';

class SessionHandler {
    private element: HTMLElement;
    private docassembleSessionsEndpoint: string | null;
    private sessionStorage: DocassembleSession[] = [];
    private sessionsOrderBy: string = 'desc';
    private paginationLimit: number = 10;
    private sessionsFilterBy: string[] = [];
    private sessionSearchInput: HTMLInputElement | null;
    private _trans: Function;

    constructor(element: HTMLElement, trans: Function) {
        this.element = element;
        this.docassembleSessionsEndpoint = element.getAttribute('data-docassemble-sessions') ?? null;
        this.sessionSearchInput = this.element.querySelector('.as__input.dws__account-docassemble__session-search-input');
        this._trans = trans;

        this.initialize();
    }

    private initialize() {
        this.fetchSessionData()
            .then(() => {
                this.createPagination(this.sessionStorage);
                this.initSessionFilter();
            });

        const sessionSearchForm = this.element.querySelector('.dws__account-docassemble__session-search-form') as HTMLFormElement | null;

        if (sessionSearchForm) {
            sessionSearchForm.addEventListener('submit', this.handleSearchFormSubmit.bind(this));
        }

        if (this.sessionSearchInput) {
            this.sessionSearchInput.addEventListener('input', this.handleSearchFormInput.bind(this));
        }

        const selectSortingElement = this.element.querySelector('#dws__account-docassemble__session-sort') as HTMLSelectElement | null;
        if (selectSortingElement) {
            selectSortingElement.addEventListener('change', this.handleSortingChange.bind(this));
        }

        document.addEventListener(CUSTOM_INTERVIEWS_UPDATED, this.updateInterviewToSessionLinks.bind(this));
        document.addEventListener(CUSTOM_SEARCH_EVENT, () => {
            this.element.scrollIntoView({ behavior: "smooth" });
        });
    }

    private async fetchSessionData(): Promise<void> {
        if (!this.docassembleSessionsEndpoint) return;

        try {
            const response = await fetch(this.docassembleSessionsEndpoint);
            const data = await response.json();

            this.sessionStorage = data?.sessions ?? [];
        } catch (error) {
            console.error('fetch operation docassemble-sessions failed:', error);
        }
    }

    private async searchSessionsByTerm(term: string): Promise<DocassembleSession[]> {
        const options = {
            includeScore: true,
            keys: ['interview.title', 'interview.subtitle', 'interview.shortTitle', 'interview.metaSubtitle']
        };

        const fuse = new Fuse(this.sessionStorage, options);
        return fuse.search(term).map(result => result.item);
    }

    private renderSessionListing( sessions: DocassembleSession[] = []) {
        const sessionContainer = this.element.querySelector('#dws__account-docassemble__session-listing-results') as HTMLElement | null;
        if (!sessionContainer) return;

        if (sessions.length <= 0) {
            sessionContainer.innerHTML = `
                <div class="as__row as__mb-6">
                    <div class="as__col">
                        <p class="as__text as__center as__text__medium">${this._trans('account.docassemble.empty.sessions')}</p> 
                    </div>
                </div>  
            `;
            return;
        }

        if (this.sessionsFilterBy.length > 0) {
            sessions = sessions.filter(session => {
                return session.interview.tags.some(tag => {
                    return this.sessionsFilterBy.includes(tag.label);
                })
            });
        }

        sessions.sort((a, b) => new Date(this.sessionsOrderBy === 'desc' ? b.modificationDate : a.modificationDate).getTime() - new Date(this.sessionsOrderBy === 'desc' ? a.modificationDate : b.modificationDate).getTime());

        sessionContainer.innerHTML = sessions.map(session => `
            <div class="as__row as__mb-6" id="${session.sessionId}" data-docassemble-session>
                <div class="as__col-12 as__hide-lg">
                    <div class="as__border as__pa-2">
                        <div class="as__row">
                            <div class="as__col-10">
                                <p class="as__mt-0">${session.interview.shortTitle}</p>
                                <a href="${session.interviewUrl}" target="_blank" class="as__link as__show as__link--text-decoration-inverse">${session.interview.metaTitle}</a>
                                <p class="as__text as__mb-0">${session.interview.metaSubtitle ?? ''}</p>
                                <p class="as__text">${session.interview.subtitle ?? ''}</p>
                                <p class="as__mb-0">
                                    <span class="as__font-weight--bold">Stand: </span>
                                    ${this.formatDate(session.modificationDate)}
                                </p>
                                <div class="as__row as__col as__mb-2 as__mt-2">
                                    <a data-docassemble-session-delete href="${session.deleteUrl}" class="as__link as__link--icon as__mb-3 as__mr-8"><svg role="img" class="as__icon as__link--icon__icon as__icon__trash"><use xlink:href="#as__icon__trash"></use></svg></a>
                                    <a href="${session.interviewUrl}" class="as__link as__link--icon as__mb-3"><svg role="img" class="as__icon as__link--icon__icon as__icon__pen"><use xlink:href="#as__icon__pen"></use></svg></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="as__col-8 as__hide as__show-lg--flex">
                    <div>
                        <span class="as__mr-2">${session.interview.shortTitle}</span>
                    </div>
                    <div>
                        <a href="${session.interviewUrl}" target="_blank" class="as__link as__link--text-decoration-inverse">${session.interview.metaTitle}</a>
                        <span class="as__text">${session.interview.metaSubtitle ?? ''}</span>
                        <p class="as__text">${session.interview.subtitle ?? ''}</p>
                    </div>
                </div>
                <div class="as__col-1 as__hide as__show-lg"></div>
                <div class="as__col-3 as__hide as__show-lg">
                    <div class="as__row">
                        <div class="as__col">${this.formatDate(session.modificationDate)}</div>
                        <div class="as__col-4 as__show--flex as__between">
                            <a data-docassemble-session-delete href="${session.deleteUrl}" class="as__link as__link--icon as__mb-3"><svg role="img" class="as__icon as__link--icon__icon as__icon__trash"><use xlink:href="#as__icon__trash"></use></svg></a>
                            <a href="${session.interviewUrl}" class="as__link as__link--icon as__mb-3"><svg role="img" class="as__icon as__link--icon__icon as__icon__pen"><use xlink:href="#as__icon__pen"></use></svg></a>
                        </div>
                    </div>
                </div>
            </div>
        `).join('');

        this.updateSessionDeleteLinks();
    }

    private formatDate(dateString: string): string {
        const date = new Date(dateString + 'Z'); // Adding the "Z" suffix ensures that the dateString is interpreted as utc time.
        return `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
    }

    private initSessionFilter() {
        const filterOptionContainerElement = this.element.querySelector('.dws__account-docassemble__session .dws__m17-filter .as__accordion__content-wrapper') as HTMLElement | null;
        if (!filterOptionContainerElement) return;

        filterOptionContainerElement.innerHTML = '';

        const tags = new Set<string>();
        this.sessionStorage.forEach(session => {
            session.interview.tags.forEach(tag => {
                tags.add(tag.label);
            });
        });
        const sessionTagGroup = Array.from(tags);

        filterOptionContainerElement.innerHTML = `
            <div class="as__checkbox-group">
                ${sessionTagGroup.map((tag, index) => `
                    <div class="as__checkbox">
                        <input id="tag-${index}" type="checkbox" value="${tag}" class="as__checkbox__control">
                        <label for="tag-${index}" class="as__label as__checkbox__label as__checkbox-icon">${tag}</label>
                    </div>
                `).join('')}
            </div>
        `;

        this.sessionsFilterBy = [];
        filterOptionContainerElement.addEventListener('click', e => {
            const target = e.target as HTMLElement;
            if (target.tagName === 'INPUT') {
                const value = (target as HTMLInputElement).value;
                if (!this.sessionsFilterBy.includes(value)) {
                    this.sessionsFilterBy.push(value);
                } else {
                   this.sessionsFilterBy = this.sessionsFilterBy.filter(item => item !== value);
                }

                let results: DocassembleSession[] = [];

                if (this.sessionsFilterBy.length > 0) {
                    results = this.sessionStorage.filter(session => {
                        return session.interview.tags.some(tag => {
                            return this.sessionsFilterBy.includes(tag.label);
                        })
                    });
                }

                if (results.length > 0) {
                    this.createPagination(results);
                } else {
                    this.createPagination(this.sessionStorage);
                }
            }
        });
    }

    private createPagination(items: DocassembleSession[]) {
        const paginationContainer = this.element.querySelector('.dws__m19-pagination') as HTMLElement | null;
        const paginationListContainer = this.element.querySelector('.dws__m19-pagination__list') as HTMLElement | null;

        if (!paginationContainer || !paginationListContainer || items.length < this.paginationLimit) {
            if (paginationContainer) paginationContainer.classList.add('as__hide');
            this.renderSessionListing(items);
            return;
        }

        paginationContainer.classList.remove('as__hide');
        paginationListContainer.innerHTML = '';

        const pageCount = Math.ceil(items.length / this.paginationLimit);
        let currentPage = 1;

        const renderPaginationItem = (pageNum: number) => {
            return `
                <li class="as__col dws__m19-pagination__list-item ${pageNum === currentPage ? 'dws__m19-pagination__list-item--active' : ''}">
                    <a href="#" class="dws__m19-pagination__list-link" data-page-index="${pageNum}">
                        <span class="dws__m19-pagination__list-link-label">${pageNum}</span>
                    </a>
                </li>
            `;
        };

        const renderPagination = () => {
            paginationListContainer.innerHTML = Array.from({ length: pageCount }, (_, i) => renderPaginationItem(i + 1)).join('');

            const listItems = paginationListContainer.querySelectorAll('.dws__m19-pagination__list-link');
            listItems.forEach(listItem => {
                listItem.addEventListener('click', event => {
                    event.preventDefault();
                    const pageNum = parseInt(listItem.getAttribute('data-page-index') || '1', 10);
                    setCurrentPage(pageNum);
                });
            });
        };

        const setCurrentPage = (pageNum: number) => {
            currentPage = pageNum;
            const prevRange = (pageNum - 1) * this.paginationLimit;
            const currRange = pageNum * this.paginationLimit;

            const results = items.slice(prevRange, currRange);
            this.renderSessionListing(results);
            renderPagination();
        };

        renderPagination();
        setCurrentPage(1);
    }

    private handleSearchFormSubmit(event: Event) {
        event.preventDefault();
        const formData = new FormData(event.currentTarget as HTMLFormElement);
        let term = formData.get('term') as string;

        if (term === '') {
            this.createPagination(this.sessionStorage);
            return;
        }

        this.searchSessionsByTerm(term)
            .then(results => {
                this.createPagination(results);
            });
    }

    private handleSearchFormInput(event: Event) {
        event.preventDefault();
        if (!(event.target instanceof HTMLInputElement)) return;
        let term = event.target.value;

        if (term === '') {
            this.createPagination(this.sessionStorage);
            return;
        }

        this.searchSessionsByTerm(term)
            .then(results => {
                this.createPagination(results);
            });
    }

    private handleSortingChange(event: Event) {
        event.preventDefault();
        const selectSortingElement = event.currentTarget as HTMLSelectElement;
        this.sessionsOrderBy = selectSortingElement.value;
        const sessionSearchForm = this.element.querySelector('.dws__account-docassemble__session-search-form') as HTMLFormElement | null;

        if (!sessionSearchForm || typeof sessionSearchForm.get === 'undefined' || !sessionSearchForm.get('term')) {
            this.createPagination(this.sessionStorage);
        } else {
            this.searchSessionsByTerm(sessionSearchForm.get('term') as string)
                .then(results => {
                    this.createPagination(results);
                });
        }
    }

    private updateInterviewToSessionLinks() {
        var interViewIds = this.sessionStorage.map((item) => item.interview.id);
        interViewIds = [...new Set(interViewIds)];

        interViewIds.forEach((id) => {
            let interviewDocument = document.querySelector('[data-docassemble-interview-id="' + id + '"]');
            let link = interviewDocument?.querySelector('[data-docassemble-interview-open-version-link]');

            link?.classList.remove('as__hide')
        });
    }

    private updateSessionDeleteLinks(){
        var deleteLinks = document.querySelectorAll('[data-docassemble-session-delete]');

        deleteLinks.forEach(function(link) {
            link.addEventListener('click', function(event) {
                event.preventDefault();

                var deleteUrl = link.getAttribute('href');
                var sessionItem = link.closest('[data-docassemble-session]');

                if (deleteUrl == null) {
                    return;
                }

                var xhr = new XMLHttpRequest();
                xhr.open('DELETE', deleteUrl, true);
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 204 && sessionItem != null) {
                       sessionItem.remove();
                    }
                };
                xhr.send();
            });
        });
    }
}

export { SessionHandler, CUSTOM_SEARCH_EVENT, CUSTOM_INTERVIEWS_UPDATED }
